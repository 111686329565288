.logo{
  margin-top: 44px;
  margin-bottom: 28px;
  color: #fff;
  font-size: 54px;
}

.logo-text{
  background: linear-gradient(#e18700, #ffd43a);
  background-clip: text;
  -webkit-backface-visibility: text;
  -webkit-text-fill-color: transparent;
}